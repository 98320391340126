<template>
	<breadcrumbs current-item-title="Repositories" :items="breadcrumbItems" />

	<page-header
		name="Repositories"
		:button-name="loading ? 'Re-caching' : 'Re-cache Repositories'"
		:on-create-click="recacheRepositories"
	/>
	<data-table
		:data-source="dataSource"
		:header-mapping="headerMapping"
		:select-at-row="selectAtRow"
	/>
</template>

<script>
import PageHeader from "../components/PageHeader";
import DataTable from "../components/DataTable";
import request from "../request";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import {Snackbar} from "@varlet/ui";

export default {
	name: "Repositories",
	components: {
		Breadcrumbs,
		PageHeader,
		DataTable
	},
	data() {
		return {
			loading: false,
			dataSource: (page, rpp) => new Promise((resolve, reject) => {
				request.get(process.env.VUE_APP_BASE_URL + '/accounts/' + this.$route.params.id + '/repositories?page=' + page + '&rpp=' + rpp)
					.then(response => resolve(response))
					.catch(error => reject(error))
			}),
			headerMapping: {
				name: 'Name',
				full_name: 'Full Name',
				private: 'Private',
				html_url: 'URL',
				default_branch: 'Default Branch'
			},
			breadcrumbItems: [
				{
					title: 'Linked Accounts',
					to: 'Accounts',
					params: {}
				}
			]
		}
	},
	methods: {
		selectAtRow(id) {},
		recacheRepositories() {
			this.loading = true
			request.post(process.env.VUE_APP_BASE_URL + `/accounts/${this.$route.params.id}/recache_repositories`)
				.then(data => {
					this.loading = false
					// Snackbar['success']("Successfully re-cached the repositories")

					this.$router.go(0)
				})
				.catch(error => {
					this.loading = false
					Snackbar['error']("Failed to re-cache the repositories")

					console.log(error)
				})
		}
	}
}
</script>

<style scoped>

</style>